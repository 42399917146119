/* eslint-disable max-lines */
/* eslint-disable import/max-dependencies */
import '@mediahuis/chameleon-reset';
import '@mediahuis/chameleon-theme-wl/fonts.css';

import { AppProps } from 'next/app';
import Head from 'next/head';
import { ReactElement, useMemo } from 'react';

import '@hubcms/brand/scss/variables.css';
import { EnvProvider } from '@hubcms/data-access-env';
import { ModalProvider } from '@hubcms/data-access-modal';
import { NavigationProvider } from '@hubcms/data-access-navigation';
import { ScenarioProvider } from '@hubcms/data-access-paywall';
import { TrackingDataProvider } from '@hubcms/data-access-tracking';
import { isAudioVisualArticleContext, isTextualArticleContext } from '@hubcms/domain-cook';
import { AuthProvider } from '@hubcms/feature-auth';

import { AppMeta } from '../components/PageHead/AppMeta';
import { PageProps } from '../domain/PageProps';

import './global.css';
import './theme-ext.css';

export default function App({ Component, pageProps }: AppProps<PageProps>): ReactElement {
  const trackingData = useMemo(() => {
    if (
      pageProps.data &&
      'context' in pageProps.data &&
      (isTextualArticleContext(pageProps.data.context) || isAudioVisualArticleContext(pageProps.data.context))
    ) {
      return { pageid: pageProps.data.context.id };
    }

    return {};
  }, [pageProps.data]);

  return (
    <EnvProvider value={pageProps.env}>
      <Head>
        <link rel="icon" href="/favicon.svg" type="image/svg+xml" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
      </Head>
      {pageProps.metaData && (
        <AppMeta metaData={pageProps.metaData} titleSuffix={pageProps.data?.sectionParams['boilerplate.brandname']} />
      )}
      <AuthProvider>
        <ScenarioProvider value={pageProps.scenario ?? 'consented'}>
          <ModalProvider>
            <TrackingDataProvider value={trackingData}>
              <NavigationProvider>
                <main style={{ marginBlockEnd: 'var(--scale-9)' }}>
                  <Component {...pageProps} />
                </main>
              </NavigationProvider>
            </TrackingDataProvider>
          </ModalProvider>
        </ScenarioProvider>
      </AuthProvider>
    </EnvProvider>
  );
}
